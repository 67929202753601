import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ResetUnlockBanner } from "../reset-unlock/banner";
import TGIcon from "../../../../shared/tg-icon";
import TGInput from "../../../../shared/tg-input";
import TGButtonVariants from "../../../../shared/tg-button-variants";
import styles from "./rop.change.password.module.scss";
import { ChangePasswordData } from "../../models/models";
import { t } from "i18next";
import { RootState } from "../../slice/RootReducer";
import { Modal, Spinner } from "react-bootstrap";
import useScreenSize from "../../../../utils/hook/useScreenSize";
import {
  changePasswordDataRequest,
  clearOtpRefKeyResponse,
} from "../../slice/changePasswordSlice";
import TGModal from "../../../../shared/tg-modal";
import LoginOTP from "../../../../shared/login-widget/login-otp";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const RopChangePassword = (props: any) => {
  const [error, setErrorCode] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errors, setErrors] = useState<Partial<ChangePasswordData>>({});
  const [isValid, setIsValid] = useState<boolean>(true);
  const [showModal, setShowModal] = useState(false);
  const [hasConfirmedDuplicate, setHasConfirmedDuplicate] = useState(false);
  const [showOtpModal, setShowOtpModal] = useState(false);
  const [isSessionExpired, setIsSessionExpired] = useState<boolean>(false);
  const [enabledTooManyCode, setEnabledTooManyCode] = useState<boolean>(false);
  const [loginShow, setloginShow] = useState<boolean>(true);
  const [fullscreen, setFullScreen] = useState<true | undefined>(undefined);
  const [passwordData, setPasswordData] = useState<ChangePasswordData>({
    oldPin: "",
    newPin: "",
    confirmPassword: "",
  });
  const [criteria, setCriteria] = useState({
    minLength: false,
    hasNumber: false,
    hasAlphabet: false,
    hasSpecialChar: true,
  });

  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const isMobileView: boolean = useScreenSize().device === "mobile";

  const response = useSelector(
    (state: RootState) => state?.osciReducer?.changePassword
  );

  const otpResponse = useSelector(
    (state: RootState) => state?.osciReducer?.changePasswordotpData
  );

  // Function to display the validation of empty input fields
  const getFieldLabel = (fieldName: keyof ChangePasswordData) => {
    const labels: { [key in keyof ChangePasswordData]: string } = {
      oldPin: t("error_signedin_current_password_required"),
      newPin: t("error_signedin_new_password_required"),
      confirmPassword: t("error_signedin_confirm_new_password_required"),
    };
    return labels[fieldName];
  };

  // Function for performing validation once user click on Continue button
  const handleSubmit = (event: React.FormEvent<HTMLButtonElement>) => {
    const newErrors: Partial<ChangePasswordData> = {};

    Object.entries(passwordData).forEach(([key, value]) => {
      if (!value?.trim()) {
        newErrors[key as keyof ChangePasswordData] = getFieldLabel(
          key as keyof ChangePasswordData
        );
      } else if (key === "newPin" && !isValidPasswordFormat()) {
        newErrors[key as keyof ChangePasswordData] = t(
          "enroll_password_invalid"
        );
      } else if (key === "confirmPassword" && !isPasswordMatch(value)) {
        newErrors[key as keyof ChangePasswordData] = t(
          "enroll_password_mismatch"
        );
      } else if (
        key === "oldPin" &&
        isDuplicatePassword(value) &&
        isPasswordMatch(value)
      ) {
        if (!hasConfirmedDuplicate) {
          setShowModal(true);
          setShowOtpModal(false);
        }
      }
    });
    setErrors(newErrors);

    // If there is no error, make API call
    if (Object.keys(newErrors).length === 0) {
      setIsValid(true);
      dispatch(changePasswordDataRequest(passwordData));
      setErrorCode(false);
      setErrorMessage("");
    }
  };

  // Function to manage input fields data
  const handleChange = (event: any) => {
    const { name, value } = event.target;
    setPasswordData({ ...passwordData, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  // Function for performing validation for New Passowrd
  const isValidPasswordFormat = () => {
    if (
      !criteria.hasAlphabet ||
      !criteria.hasNumber ||
      !criteria.minLength ||
      criteria.hasSpecialChar
    ) {
      return false;
    }
    return true;
  };

  // Function for performing validation for New Password and Confirm password
  const isPasswordMatch = (password: string) => {
    if (passwordData.confirmPassword !== passwordData.newPin) {
      return false;
    }
    return true;
  };

  // Function for performing validation for Old Password and New password
  const isDuplicatePassword = (password: string) => {
    if (passwordData.newPin !== passwordData.oldPin) {
      return false;
    }
    return true;
  };

  // Handled Modal Close
  const handleCloseModal = (value: any) => {
    setShowModal(false);
    setShowOtpModal(false);
    if (value !== "duplicateModel") {
      dispatch(clearOtpRefKeyResponse());
    }
  };

  // Handled Ok button in Modal
  const handleOkModal = () => {
    setShowModal(false);
    setHasConfirmedDuplicate(false);
  };

  const showOrHideTooManyCodesPopUp = (value: boolean) => {
    setEnabledTooManyCode(value);
    setloginShow(false);
    sessionStorage.removeItem("temptoken");
    sessionStorage.removeItem("refNumber");
    sessionStorage.removeItem("memberId");
    sessionStorage.removeItem("reset");
    sessionStorage.removeItem("newPin");
  };

  const showSessionExpirePopUp = () => {
    setIsSessionExpired(true);
    setloginShow(false);
    sessionStorage.removeItem("temptoken");
    sessionStorage.removeItem("refNumber");
    sessionStorage.removeItem("memberId");
    sessionStorage.removeItem("reset");
    sessionStorage.removeItem("newPin");
  };

  const showOrHidePanel = (value: boolean) => {
    setShowOtpModal(false);
  };

  const onHidePanel = (value: boolean) => {
    setShowOtpModal(false);
  };

  // Function for Password Check
  const renderPasswordCheck = () => {
    return (
      <div className={styles.passwordCriteria}>
        <div className={styles.passwordCriteriaInner}>
          <span className={styles.PasswordContainText}>
            {t("label_password_rule_text")}
          </span>
          <div className={styles.criterias}>
            <span className={styles.criteria}>
              {criteria.minLength ? (
                <span className={styles.checkValid}>
                  <TGIcon icon="check-green-icon" size=" " fillColor="" />
                </span>
              ) : (
                <span className={styles.check}>
                  <TGIcon icon="check-icon" size=" " fillColor="" />
                </span>
              )}
              <div className={styles.criteriaText}>
                {t("label_password_length_rule")}
              </div>
            </span>
            <span className={styles.criteria}>
              {!criteria.hasSpecialChar ? (
                <span className={styles.checkValid}>
                  <TGIcon icon="check-green-icon" size=" " fillColor="" />
                </span>
              ) : (
                <span className={styles.check}>
                  <TGIcon icon="check-icon" size=" " fillColor="" />
                </span>
              )}
              <div className={styles.criteriaText}>
                {t("enroll_special_char")}
              </div>
            </span>
            <span className={styles.criteria}>
              {criteria.hasNumber ? (
                <span className={styles.checkValid}>
                  <TGIcon icon="check-green-icon" size=" " fillColor="" />
                </span>
              ) : (
                <span className={styles.check}>
                  <TGIcon icon="check-icon" size=" " fillColor="" />
                </span>
              )}

              <div className={styles.criteriaText}>
                {t("label_password_digit_rule")}
              </div>
            </span>
            <span className={styles.criteria}>
              {criteria.hasAlphabet ? (
                <span className={styles.checkValid}>
                  <TGIcon icon="check-green-icon" size=" " fillColor="" />
                </span>
              ) : (
                <span className={styles.check}>
                  <TGIcon icon="check-icon" size=" " fillColor="" />
                </span>
              )}

              <div className={styles.criteriaText}>
                {t("label_password_alphabet_rule")}
              </div>
            </span>
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (response?.changePasswordDetails?.data?.code === "E001") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        oldPin: t("error_current_new_duplicate"),
      }));
    } else if (response?.changePasswordDetails?.data?.otpRefKey) {
      setErrorCode(false);
      setErrors({});
      setShowOtpModal(true);
    }
  }, [response]);

  useEffect(() => {
    if (passwordData.newPin !== "") {
      const minLength = passwordData.newPin.length >= 8;
      const hasSpecialChar = /[!@#$%^&*(),.?:{}|<>"]/.test(passwordData.newPin);
      const hasNumber = /\d/.test(passwordData.newPin);
      const hasAlphabet = /[a-zA-Z]/.test(passwordData.newPin);
      setCriteria({
        minLength,
        hasSpecialChar,
        hasNumber,
        hasAlphabet,
      });
    } else {
      setCriteria({
        minLength: false,
        hasNumber: false,
        hasAlphabet: false,
        hasSpecialChar: true,
      });
    }
  }, [passwordData.newPin]);

  useEffect(() => {
    if (passwordData.newPin !== "") {
      const minLength = passwordData.newPin.length >= 8;
      const hasSpecialChar = /[!@#$%^&*(),.?:{}|<>"]/.test(passwordData.newPin);
      const hasNumber = /\d/.test(passwordData.newPin);
      const hasAlphabet = /[a-zA-Z]/.test(passwordData.newPin);
      setCriteria({
        minLength,
        hasNumber,
        hasAlphabet,
        hasSpecialChar,
      });
    } else {
      setCriteria({
        minLength: false,
        hasNumber: false,
        hasAlphabet: false,
        hasSpecialChar: true,
      });
    }
  }, [passwordData.newPin]);

  useEffect(() => {
    if (Object.keys(otpResponse?.changePasswordOTP).length > 0) {
      // Checking whether the success condition is getting satisfied
      if (otpResponse?.changePasswordOTP?.code === "I120") {
        setErrorCode(false);
        dispatch(clearOtpRefKeyResponse());
        navigate(`/${i18n.language}/myprofile`);
      } else {
        setErrorCode(true);
      }
    } else if (Object.keys(otpResponse.error).length > 0) {
      setErrorCode(true);
    } else {
      setErrorCode(false);
    }
  }, [otpResponse?.changePasswordOTP, otpResponse.error]);

  // useEffect to handle errorCodes from API
  useEffect(() => {
    if (response?.error?.response?.data?.code === "40100002") {
      setErrorMessage(t("label_credentails_incorrect"));
      setErrorCode(true);
    } else if (response?.error?.response?.data?.code === "E0199") {
      setErrorMessage(t("unlock_account"));
      setErrorCode(true);
    } else if (response?.error?.response?.data?.code === "008") {
      setErrorMessage(t("error_unauthorised"));
      setErrorCode(true);
    } else if (response?.error?.response?.data?.code === "498") {
      setErrorMessage(t("error_unauthorised"));
      setErrorCode(true);
    } else if (response?.error?.response?.data?.code === "013") {
      setErrorMessage(t("error_exceeded_otp_request"));
      setErrorCode(true);
    } else if (response?.error?.response?.data?.code === "011") {
      setShowModal(true);
    } else if (response?.error?.response?.data?.code) {
      setErrorMessage(t("error_reset_page_failed"));
      setErrorCode(true);
    } else {
      setErrorCode(false);
      setErrorMessage("");
    }
  }, [response]);

  useEffect(() => {
    if (isMobileView) {
      setFullScreen(true);
    } else {
      setFullScreen(undefined);
    }
  }, [isMobileView]);

  useEffect(() => {
    if (showModal) {
      document.documentElement.style.setProperty("--body-overflow", "hidden");
    } else {
      document.documentElement.style.setProperty("--body-overflow", "auto");
    }
    return () => {
      document.documentElement.style.setProperty("--body-overflow", "auto");
    };
  }, [showModal]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <ResetUnlockBanner banner={styles.bannerImage}>
      <>
        {/* Message to be displayed when user enters wrong data which is not registered with TG  */}
        {error && (
          <div className={styles.errorContainer}>
            <TGIcon icon="error-icon" fillColor="" size="" />
            <span className={styles.errorLabel}>{errorMessage}</span>
          </div>
        )}
        <div className={styles.passwordContainer}>
          <div className={styles.oldPinContainer}>
            <TGInput
              isEnroll={true}
              label={t("label_signedin_rop_current_password")}
              value={passwordData.oldPin}
              name="oldPin"
              onChange={handleChange}
              isError={!!errors.oldPin}
              errorLabel={errors.oldPin}
              type="password"
              validation="noSpecialChar"
            />
          </div>
          <div className={styles.primaryEnroll}>
            <TGInput
              isEnroll={true}
              label={t("label_signedin_rop_new_password")}
              value={passwordData.newPin}
              name="newPin"
              onChange={handleChange}
              isError={!!errors.newPin}
              errorLabel={errors.newPin}
              type="password"
              validation="noSpecialChar"
              maxLength={8}
            />
            {isMobileView && renderPasswordCheck()}
            <TGInput
              isEnroll={true}
              label={t("label_signedin_rop_new_password_confirm")}
              value={passwordData.confirmPassword}
              name="confirmPassword"
              onChange={handleChange}
              isError={!!errors.confirmPassword}
              errorLabel={errors.confirmPassword}
              type="password"
              validation="noSpecialChar"
              maxLength={8}
            />
          </div>
          {!isMobileView && renderPasswordCheck()}
        </div>
        <div className={styles.buttonContainer}>
          <TGButtonVariants
            label={response.isLoading ? "" : t("button_confirm")}
            display="flex"
            justifyContent="center"
            alignItems="center"
            borderRadius="30px"
            boxShadow="0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
            bgColor="#684B9B"
            padding="16px"
            textColor="#FFFFFF"
            fontFamily="Inter"
            fontSize="16px"
            fontStyle="normal"
            fontWeight="700"
            lineHeight="160%"
            borderDisabled="0.791px solid #E3D4FE"
            bgColorDisabled="#E3D4FE"
            boxShadowDisabled="0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
            bgColorHover="#684b9b"
            borderHover="0.791px solid #E3D4FE"
            boxSHadowHover="0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
            height="50px"
            width={!isMobileView ? "170px" : "100%"}
            onClick={handleSubmit}
            marginTop={isMobileView && "18px"}
          >
            {response.isLoading ? (
              <>
                <Spinner
                  animation="grow"
                  variant="light"
                  style={{
                    width: "10px",
                    height: "10px",
                    margin: "5px",
                  }}
                />
                <Spinner
                  animation="grow"
                  variant="light"
                  style={{
                    width: "10px",
                    height: "10px",
                    margin: "5px",
                  }}
                />
                <Spinner
                  animation="grow"
                  variant="light"
                  style={{
                    width: "10px",
                    height: "10px",
                    margin: "5px",
                  }}
                />
              </>
            ) : null}
          </TGButtonVariants>
        </div>
        {showModal && (
          <TGModal
            show={showModal}
            handleClose={() => {
              handleCloseModal("duplicateModel");
            }}
            buttonLabel="Ok"
            customModalClass={styles.customModalClass}
            customOffCanvasClass={styles.customOffCanvasClass}
            centered={true}
            backdrop="static"
          >
            <div className={styles["tcModalFlex"]}>
              <div className={styles["tcModal"]}>
                <TGIcon fillColor={"none"} icon="too-many-otp-icon" />
                <div className={styles["modalContent"]}>
                  <span className={styles["modalHeading"]}>
                    {t("info_duplicate_password")}
                  </span>
                  <span className={styles["modalDescription"]}>
                    {t("info_duplicate_password_message")}
                  </span>
                </div>
              </div>
              <div className={styles["modalButtonContainer"]}>
                <div>
                  <TGButtonVariants
                    label={t("button_too_many_codes_ok")}
                    disabled={false}
                    display="flex"
                    fillColor=""
                    size=""
                    align="left"
                    justifyContent="center"
                    alignItems="center"
                    borderRadius="30px"
                    boxShadow="0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
                    bgColor="#684B9B"
                    padding="12px 16px"
                    textColor="#FFF"
                    fontFamily="Inter"
                    fontSize="16px"
                    fontStyle="normal"
                    fontWeight="700"
                    lineHeight="160%"
                    bgColorHover="#684B9B"
                    height="50px"
                    width={isMobileView ? "100%" : "170px"}
                    onClick={handleOkModal}
                  />
                </div>
              </div>
            </div>
          </TGModal>
        )}
        {showOtpModal && (
          <Modal
            show={showOtpModal}
            onHide={() => {
              handleCloseModal("otpModel");
            }}
            centered
            className={`${styles["sign-in-rop-modal"]} ${
              fullscreen ? styles["parentContainer"] : ""
            } ${
              !loginShow && !fullscreen && styles["modal-container-rop-flow"]
            }`}
            fullscreen={fullscreen}
          >
            <Modal.Header closeButton className={styles.borderBottom}>
              <Modal.Title className={styles.titleWrapper}>
                {!showModal && (
                  <span
                    className="arrow"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setShowOtpModal(false);
                    }}
                  >
                    <TGIcon icon="arrow-left" fillColor="#684B9B" size="20" />
                  </span>
                )}
                <div className={styles.otpTitleSignIn}>
                  {t("label_otp_title")}
                </div>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className={styles.otpModalBody}>
              <LoginOTP
                onHideModal={() => onHidePanel}
                isHidden={showOrHidePanel}
                updateUserName={props.loginName}
                showFrequentCodePopUp={showOrHideTooManyCodesPopUp}
                showSessionExpirePopUp={showSessionExpirePopUp}
                handleSuccess={() => props.handleSuccess}
                keyLoggedInProfile={"ROP"}
                isChangePassword={true}
              />
            </Modal.Body>
          </Modal>
        )}
      </>
      {/* If the condition got success, page has to be redirect to Success page */}
    </ResetUnlockBanner>
  );
};
